module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ionos.hu/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-why-did-you-render/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4c03927231a6fc1410c4daa20774497b@sentry-public.online.pro/2","environment":"ionos-hu_production","enabled":true,"autoSessionTracking":true,"integrations":[{"name":"BrowserTracing","_hasSetTracePropagationTargets":false,"options":{"idleTimeout":1000,"finalTimeout":30000,"heartbeatInterval":5000,"markBackgroundTransactions":true,"startTransactionOnLocationChange":true,"startTransactionOnPageLoad":true,"enableLongTask":true,"_experiments":{},"traceFetch":true,"traceXHR":true,"enableHTTPTimings":true,"tracingOrigins":["localhost",{}],"tracePropagationTargets":["localhost",{}]}}],"tracesSampleRate":1,"release":"45cd727e47e6472db58586bd918df755deeaf1df","sampleRate":1},
    },{
      plugin: require('../plugins/gatsby-plugin-marketplace/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../themes/gatsby-theme-ionos-hu/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
